import "../scss/app.scss";
import Vue from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Slick from "vue-slick";
import Rellax from "rellax";

const app = new Vue({
  el: "#app",
  components: { Slick },
  data: {
    spnav: false,
    toggleMenu: false,
    test: true,
    slickLoaded: false,
    slickOptions: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      fade: true,
      cssEase: "linear",
    },
  },
  mounted() {
    var rellax = new Rellax('.rellax');
  },
  methods: {
    handleInit(event, slick) {
      this.slickLoaded = true;
    },
  },
});
